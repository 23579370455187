import { createStore } from "vuex";

export default createStore({
  state: {
    isAuthenticated: localStorage.getItem("Auth") == "Y" ? true : false,
    currencySymbol: "₹",
    userId: localStorage.getItem("Uid"),
    userName: localStorage.getItem("Uname"),
    outletId: localStorage.getItem("OutletId"),
    outletName: localStorage.getItem("OutletName"),
    tradeMark: localStorage.getItem("TradeMark"),
    tdmId: localStorage.getItem("TdmId"),
    onlineorderCounter: 0,
    serverPath: "http://192.168.18.51:8180/ASRHAVELI/HO/src/php/",
    currentLocation: null,
    tripStarted: false,
  },
  getters: {
    currentLocation(state) {
      return state.currentLocation;
    },
  },
  mutations: {
    setCurrentLocation(state, { location }) {
      state.currentLocation = location;
    },
  },
  actions: {
    async getCurrentLocation({ commit }) {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const location = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              await commit("setCurrentLocation", { location });
              resolve(location);
            },
            (error) => {
              console.error("Error getting location:", error.message);
              reject(error);
              // You can handle errors here, e.g., by showing a user-friendly message
            }
          );
        } else {
          const errorMessage = "Geolocation is not available in this browser.";
          console.error(errorMessage);
          // You can handle the case where geolocation is not available
        }
      });
    },
  },
  modules: {},
});
