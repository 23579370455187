<!-- @format -->
<template>
  <div class="main-div">
    <message-box ref="msgbox"></message-box>
    <login-form v-if="$store.state.isAuthenticated == false"></login-form>
  </div>
</template>
<script>
import LoginForm from "@/components/LoginForm.vue";
// import axios from "axios";

export default {
  components: {
    LoginForm,
  },
  data() {
    return {
      setupdone: false,
      outletcode: "",
      serverURL: this.$store.state.serverPath,
      isSettingup: false,
      tdmId: this.$store.state.tdmId,
    };
  },
  methods: {},
  mounted() {
    // var host = window.location.protocol + "//" + window.location.host;
    // console.log("Host:" + host);
  },
};
</script>
<style>
@import "../css/formcontrol.css";
</style>
<style scoped>
.outlet-code {
  position: relative;
  margin: 0px auto !important;
  width: 325px;
  height: 450px;
  background-color: rgba(235, 171, 42, 0.698);
  border: 1px solid rgba(235, 171, 42, 0.698);
  padding: 5px;
  top: 100px;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 1px white;
}

.validate-area {
  position: relative;
  top: 20px;
}
.right-div {
  position: relative;
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.736);
  text-align: center;
  justify-content: center;
  border-radius: 8px;
}
.right-div label {
  position: relative;
  font-weight: bolder;
  font-size: 18px;
  margin-top: 50px;
}

.right-div .smtxt {
  position: relative;
  margin: 0px auto !important;
  font-size: 28px;
  text-align: center;
  width: 80%;
}
.big-btn {
  width: 80%;
  left: 5px;
  font-size: 20px;
}

.sm-img {
  position: relative;
  margin: 0px auto !important;
  width: 100%;
  height: 100%;
}
.logo-img {
  position: relative;
  top: 8px;
  width: 200px;
  height: 130px;
  padding: 8px;
}
</style>
