import LoginPage from '../views/LoginPage.vue'
const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import("../views/Transaction/OnlineOrders.vue")
  }
]


export default routes;

