<!-- @format -->

<template>
  <!-- <teleport to="#app"> -->
  <div class="notify-box d-flex align-items-center" v-if="showBox">
    <div @click="hideNotify">
      <i class="fa-solid fa-times-circle closeicon"></i>
    </div>
    <span class="fa-solid fa fa-bell"></span>&nbsp;&nbsp;&nbsp;
    <span
      style="position: relative; left: -8px; font-size: 16px; font-weight: bold"
      >{{ NotifyTitle }}</span
    ><br />
    <div class="notifytxt">
      <span v-html="NotifyText"></span>
    </div>
  </div>
  <!-- </teleport> -->
</template>
<script>
export default {
  props: ["NotifyTitle", "NotifyText", "NotifyTrackno"],
  data() {
    return {
      showBox: true,
    };
  },
  methods: {
    async hideNotify() {},
  },
  mounted() {
    // setTimeout(() => {
    //   this.hideNotify();
    // }, 5000);
  },
};
</script>
<style scoped>
.notify-box {
  position: relative;
  display: block;
  float: right;
  right: 0;
  /* right: 35px; */
  width: 350px;
  height: fit-content;
  z-index: 99999;
  padding: 15px;
  border-radius: 6px;
  color: black;
  background-color: white;
  box-shadow: 1px 1px 4px 1px lightgrey;
  margin-top: 5px;
}
.closeicon {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 18px;
}
.closeicon:hover {
  cursor: pointer;
  color: black;
}
.notifytxt {
  color: black;
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  margin-left: 25px;
}
@media only screen and (max-width: 600px) {
  .notify-box {
    width: 150px;
  }
}
</style>
