<!-- @format -->

<template>
  <div>
    <div class="mynavbr">
      <!-- <div class="bar-icon" @click="toggleSideBar()">
        <i class="fa-solid fa-bars-staggered"></i>
      </div> -->
      <div class="title-div">
        <!-- <div class="menubar fas fa-bars" @click="toggleSideBar()">Hello</div> -->
        <div class="logo-div">
          <img
            src="../assets/amritsar_logo.png"
            class="topbarlogo"
            v-if="tdmId == '0001'" />
          <img src="../assets/amritsari_logo.png" class="topbarlogo" v-else />
          <!-- <img src="../assets/complogo.png" class="topbarlogo" /> -->
        </div>

        <!--Title -->
        <router-link to="/" class="title">{{ companyName }}</router-link>
      </div>
      <div class="logout-div">
        <!-- <div class="bell-div" @click="recnotify()">
          <i
            :class="notifyClass"
            data-toggle="tooltip"
            title="Notifications"
            data-original-title="Notifications"
            data-placement="bottom"
          ></i>
          <span :class="rbadgeClass">{{ notCount }}</span>
        </div> -->
        <!-- v-if="badgeshow" -->
        <span class="logout" @click="toggleLogout"> Logout </span>
      </div>
    </div>

    <!-- Logout Box -->
    <logout-box
      v-show="logoutbox"
      @cancel="toggleLogout"
      @logout="logoutBtn"></logout-box>
    <teleport to="#app">
      <message-box ref="msgbox"></message-box>
    </teleport>
  </div>
  <notify-popup
    ref="notifylist"
    :ShowBox="notPopup"
    @mark-read="getnotcount()"></notify-popup>
  <div class="notpopupback" v-if="notPopup" @click="notPopup = !notPopup"></div>
</template>

<script>
import axios from "axios";

import LogoutBox from "./LogoutBox.vue";
import NotifyPopup from "@/components/NotifyPopup.vue";

export default {
  emits: ["toggleBar"],
  components: {
    LogoutBox,
    NotifyPopup,
  },
  data() {
    return {
      companyName: this.$store.state.outletName,
      logoutbox: false,
      userName: this.$store.state.userName,
      notifyClass: "fas fa-bell bicon notbell notify-icon",
      // rbadgeClass: "badge rounded-pill w3-count btop",
      rbadgeClass: "notify-badge",
      badgeshow: false,
      notCount: 0,
      notPopup: false,
      tdmId: this.$store.state.tdmId,
    };
  },
  methods: {
    toggleLogout() {
      this.logoutbox = !this.logoutbox;
    },
    async logoutBtn(autologout = false) {
      if (this.$store.state.tripStarted == true) {
        await this.$refs.msgbox.show({
          title: "Alert",
          message: "Please End the Trip!",
          okButton: "OK",
          msgboxType: "danger",
        });
      } else {
        localStorage.clear();
        await axios
          .post("users.php", {
            optn: "Logout",
            outletid: this.$store.state.outletId,
            uid: this.$store.state.userId,
            uname: this.$store.state.userName,
          })
          .then(() => {});

        if (autologout == true) {
          await this.$refs.msgbox.show({
            title: "Alert",
            message: "Auto Logout! User was Ideal for 30 Mins.!",
            okButton: "OK",
            msgboxType: "info",
          });
        }
        this.$store.state.userId = "";
        this.$store.state.isAuthenticated = false;
        this.$router.push("/");
      }
    },
    toggleSideBar() {
      this.$emit("toggleBar");
    },
    getCompany() {},
  },
  mounted() {
    // setInterval(async () => {
    //   await this.checkUsers();
    // }, 2000);
  },
};
</script>
<style>
@import "../css/base.css";
@import "../css/bell-animation.css";
</style>
<style scoped>
.mynavbr {
  position: fixed;
  width: 100%;
  top: 0px;
  display: block;
  box-shadow: 0px 1px 8px 2px lightgray;
  text-align: left;
  padding: 0px;
  z-index: 999;
  background-color: white;
}

.topbarlogo {
  height: 35px;
}
.mynavbr .bar-icon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  left: 4px;
  top: -8px;
  padding: 6px;
}

.mynavbr .bar-icon:hover {
  border-radius: 5px;
  background-color: lightgray;
}

.mynavbr .title-div,
.logout-div {
  /* width: calc(50% - 20px); */
  display: inline-block;
  position: relative;
  padding: 6px;
}

.mynavbr .logo-div {
  position: relative;
  display: inline-block;
}

.mynavbr .title-div {
  position: relative;
  text-align: left;
  left: 5px;
}

.mynavbr .title-div .title {
  position: relative;
  left: 8px;
  top: -8px;
  text-decoration: none;
  color: var(--theme-color);
  font-size: 22px;
  font-weight: 600;
}
.logout-div .bell-div {
  display: inline-block;
  top: 0px;
  text-align: right;
  right: 100px;
  color: slategray;
}

.mynavbr .logout-div {
  position: relative;
  top: 10px;
  float: right;
  text-align: right;
  right: 10px;
}

.logout {
  font-size: 15px;
  font-weight: 700;
  color: slategray;
  cursor: pointer;
}
.logout:hover {
  color: #2c3e50;
}

.username {
  position: relative;
  color: #2c3e50;
  margin-right: 5px;
}

.fa-bars {
  display: none;
}
.notpopupback {
  z-index: 1000;
  opacity: 0.5;
  background-color: black;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.notify-badge {
  position: absolute;
  font-size: 13px;
  border: 1px solid lightgray;
  background-color: white;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 25px;
  top: -5px;
  right: 65px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .mynavbr .bar-icon {
    left: 4px;
    top: -5px;
  }
  .topbarlogo {
    height: 25px;
  }
  .mynavbr .title-div .title {
    top: -8px;
    font-size: 11px;
    font-weight: 600;
    color: var(--theme-color);
  }
  .logout {
    position: relative;
    top: -5px;
    font-size: 12px;
    font-weight: 600;
  }
  .logout-div .bell-div {
    position: relative;
    display: inline-block;
    top: -4px;
    text-align: right;
    right: 0px;
    color: slategray;
  }

  .notify-badge {
    font-size: 10px;
    top: -10px;
    right: 5px;
  }
}
</style>
