<!-- @format -->
<template>
  <div class="outer-div">
    <div class="login-div">
      <teleport to="#app">
        <ConfirmBox ref="confirm"></ConfirmBox>
      </teleport>
      <card-loader
        v-show="isChecking"
        :LoaderText="'Authenticating..'"></card-loader>
      <div class="inner-div" v-show="!isChecking">
        <div class="logo-div">
          <!-- <img src="../assets/amritsarihaveligroup.png" class="logo" /> -->
          <img src="../assets/deliveryboy.png" class="delv-logo" />
        </div>
        <div class="login-form">
          <span class="logintxt tocenter">Login</span>

          <span class="errmsg" :class="errclass" v-if="err">{{ errmsg }}</span>

          <label for="uname">User Name:</label>
          <input
            type="text"
            name="uname"
            class="form-control"
            v-model="uname"
            maxlength="20"
            autocomplete="off"
            autofocus />

          <label for="upass">Password:</label>
          <input
            type="password"
            name="upass"
            class="form-control"
            v-model="upass"
            maxlength="20"
            autocomplete="off"
            v-on:keydown.enter="login()" />
          <button class="btn btn-success" type="button" @click="login()">
            Login
          </button>
        </div>
      </div>
      <div class="devcontact">
        <img src="../assets/kvr.png" class="devlogo" />
        <a href="mailto:ssoftwares323@rediffmail.com">KVR Technologies</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CardLoader from "./CardLoader.vue";
export default {
  name: "LoginForm",
  emits: ["login-btn"],
  components: { CardLoader },
  data() {
    return {
      uname: "",
      upass: "",
      err: false,
      errmsg: "",
      errclass: "",
      isChecking: false,
      pageName: "deliveryboy.php",
      tdmId: this.$store.state.tdmId,
      showdownload: false,
    };
  },
  methods: {
    async login() {
      this.uname = this.uname.toUpperCase();
      this.upass = this.upass.toUpperCase();
      if (this.uname != "" && this.upass != "") {
        this.isChecking = true;
        this.err = false;
        this.errclass = "warnmsg";
        this.errmsg = "Authenticating...";
        var response;
        await axios
          .post(this.pageName, {
            optn: "CheckUser",
            uname: this.uname,
            upass: this.upass,
          })
          .then(function (resp) {
            response = resp.data;
          });
        this.isChecking = false;
        if (response.Errorsts === false) {
          if (response.List.length > 0) {
            this.errclass = "donemsg";
            this.errmsg = "Welcome!" + this.uname;
            var Listdata = response.List[0];
            if (Listdata.activeyn != "Y") {
              this.err = true;
              this.errclass = "dangermsg";
              this.errmsg = "User is not Active!";
            } else {
              localStorage.setItem("Auth", "Y");
              localStorage.setItem("Uid", Listdata.dlvid);
              localStorage.setItem("Uname", Listdata.dlvname);
              localStorage.setItem("OutletId", Listdata.outletid);
              localStorage.setItem("OutletName", Listdata.outletname.trim());
              this.$store.state.userId = Listdata.dlvid;
              this.$store.state.userName = Listdata.dlvname;
              this.$store.state.isAuthenticated = true;
              this.$store.state.outletId = Listdata.outletid;
              this.$store.state.outletName = Listdata.outletname.trim();
              this.$router.push("/orders");
            }
          } else {
            this.err = true;
            this.errclass = "dangermsg";
            this.errmsg = "Invalid Username/Password!";
          }
        } else {
          this.err = true;
          this.errclass = "dangermsg";
          this.errmsg = "Unable to Login! Something Went Wrong!";
          console.log(response.ErrorMsg);
        }
      } else {
        this.err = true;
        this.errmsg = "Please Fill User Name/Password!";
        this.errclass = "dangermsg";
      }
    },
    nextBox(ele) {
      const currentIndex = Array.from(ele.form.elements).indexOf(ele);
      ele.form.elements
        .item(
          currentIndex < ele.form.elements.length - 1 ? currentIndex + 1 : 0
          // currentIndex + 1
        )
        .focus();
    },
    async loginLog() {
      await axios
        .post("deliveryboy.php", {
          optn: "Login",
          outletid: this.$store.state.outletId,
          uid: this.$store.state.userId,
          uname: this.$store.state.userName,
        })
        .then(() => {
          // console.log(resp);
        });
    },
    toggleDownload() {
      this.showdownload = !this.showdownload;
    },
  },
};
</script>

<style>
@import "../css/base.css";
</style>
<style scoped>
.outer-div {
  position: relative;
  margin: 0px auto !important;
  width: fit-content;
  height: fit-content;
  background-color: rgba(10, 10, 10, 0.698);
  border: 1px solid rgba(0, 0, 0, 0.698);
  padding: 5px;
  top: 50px;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 1px white;
}

.login-div {
  position: relative;
  margin: 0px auto !important;
  left: 0;
  right: 0;
  width: 350px;
  border-radius: 4px;
  padding: 8px;
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: left;
  /* box-shadow: 0px 2px 4px 1px lightgray; */
  background-color: rgba(255, 255, 255, 0.903);
}

.inner-div {
  padding: 0px;
}

.login-form {
  position: relative;
  display: block;
  width: calc(100% - 50px);
  margin: 0 auto !important;
  top: 0px;
  padding: 2px;
}
.login-form .form-control {
  padding: 8px;
  font-size: 18px;
  font-weight: lighter;
  margin-top: 12px;
  margin-bottom: 12px;
  width: calc(100% - 20px);
}

label {
  position: relative;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
  display: block;
}

.logintxt {
  position: relative;
  display: block;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
.btn {
  display: block;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.errmsg {
  position: relative;
  display: block;
  text-align: center;
  padding: 3px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}
.dangermsg {
  background-color: #f12b08f2;
  color: white;
}
.warnmsg {
  background-color: #ebca12;
  color: white;
}
.donemsg {
  background-color: #05bb23;
  color: white;
}

.devcontact {
  position: relative;
  display: block;
  margin-top: 5px;
  text-align: center;
}
.devcontact a {
  font-size: 15px;
  font-weight: bold;
  color: gray;
}
.font-18 {
  font-size: 18px;
}
.font-16 {
  font-size: 16px;
}

.logo-div {
  position: relative;
  display: block;
  padding: 3px;
  text-align: center;
}
.logo {
  position: relative;
  display: inline-block;
  margin: 0px auto !important;
  position: relative;
  width: 80px;
}
.delv-logo {
  position: relative;
  display: inline-block;
  margin: 0px auto !important;
  position: relative;
  width: 120px;
}

.devlogo {
  position: relative;
  width: 55px;
  display: block;
  margin: 0px auto !important;
}
@media only screen and (max-width: 600px) {
  .outer-div {
    top: 10%;
  }
}
@media only screen and (max-width: 400px) {
  .login-div {
    width: 300px;
  }

  .outer-div {
    top: 10%;
  }
  .logo {
    position: relative;
    display: block;
    margin: 0px auto !important;
    position: relative;
    width: 80px;
  }

  .delv-logo {
    position: relative;
    display: block;
    margin: 0px auto !important;
    position: relative;
    width: 80px;
  }

  .inner-div {
    height: 360px;
  }
}
</style>
