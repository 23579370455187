<!-- @format -->

<template>
  <div class="errdot"></div>
</template>
<style scoped>
.errdot {
  position: relative;
  display: inline-block;
  top: -5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
}
</style>
