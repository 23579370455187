import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueCryptojs from "vue-cryptojs";

// Golbal Components
import MandatoryIcon from "./components/MandatoryIcon.vue";
import AlertBox from "./components/AlertBox.vue";
import CardLoader from "./components/CardLoader.vue";
import CloseBtn from "@/components/CloseBtn.vue";
import GoBack from "@/components/GoBack.vue";
import ConfirmBox from "@/components/ConfirmBox.vue";
import MessageBox from "@/components/MessageBox.vue";
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
import VueQRCodeComponent from "vue-qrcode-component";
// import ip from "./assets/ipconfig.json";
// axios.defaults.baseURL = "http://" + ip.ip + "/php/";

// For Deployment
// axios.defaults.baseURL = window.location.protocol + "//" + window.location.host + "/php/";
axios.defaults.baseURL = "https://delivery.amritsarhaveli.in/php/";

// For Testing

// axios.defaults.baseURL = window.location.protocol + "//" + window.location.hostname + ":8180" + "/ASRHAVELI/outlet/src/php/";

// axios.defaults.baseURL =
//   "http://192.168.29.51:8180/ASRHAVELI/delivery/src/php/";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCryptojs);
app.use(Vue3SimpleHtml2pdf);

app.component("qr-code", VueQRCodeComponent);
app.component("MandatoryIcon", MandatoryIcon);
app.component("AlertBox", AlertBox);
app.component("CardLoader", CardLoader);
app.component("CloseBtn", CloseBtn);
app.component("GoBack", GoBack);
app.component("ConfirmBox", ConfirmBox);
app.component("MessageBox", MessageBox);

app.mount("#app");

//createApp(App).use(store).use(router).mount('#app')
