<template>
  <popup-modal ref="popup">
    <span class="msg-title">{{ title }}</span>
    <div class="mymsg">
      <span class="myicon fa-solid" :class="geticonClass"></span>
      <span class="msg-txt" v-html="message"></span>
    </div>
    <div class="btns">
      <button class="btn btn-primary" ref="okbtn" @click="_confirm">
        {{ okButton }}
      </button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";

export default {
  name: "ConfirmDialogue",
  components: { PopupModal },
  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "Cancel", // text for cancel button
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
    msgboxType: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      this.msgboxType = opts.msgboxType;
      // if (opts.cancelButton) {
      //   this.cancelButton = opts.cancelButton;
      // }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      setTimeout(() => {
        if (this.$refs.okbtn != undefined) {
          this.$refs.okbtn.focus();
        }
      }, 200);
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
  computed: {
    geticonClass: function () {
      var alerticon = "";
      if (this.msgboxType == "success") {
        alerticon = "fa-circle-check";
      }
      if (this.msgboxType == "danger") {
        alerticon = "fa-circle-xmark";
      }
      if (this.msgboxType == "warn") {
        alerticon = "fa-triangle-exclamation";
      }
      if (this.msgboxType == "info") {
        alerticon = "fa-circle-info";
      }
      if (this.msgboxType == undefined) {
        alerticon = "fa-triangle-exclamation";
      }

      return alerticon;
    },
  },
};
</script>

<style scoped>
.btns {
  position: relative;
  width: calc(100% - 15px);
  display: block;
  text-align: right;
  justify-content: right;
  padding: 10px;
  margin-top: 10px;
}
.btns .btn {
  position: relative;
  margin-left: 5px;
  padding: 8px;
  font-size: 14px;
  max-width: fit-content;
}

.msg-title {
  font-size: 22px;
  font-weight: bold;
}

.mymsg {
  position: relative;
  top: 5px;
  display: block;
}

.myicon {
  position: relative;
  font-size: 30px;
  margin-right: 15px;
  top: 5px;
}

.fa-circle-check {
  color: #198754;
}
.fa-circle-xmark {
  color: #d34747;
}
.fa-triangle-exclamation {
  color: #f1ce64;
}

.fa-circle-info {
  color: #255acc;
}

@media only screen and (max-width: 400px) {
}
</style>
