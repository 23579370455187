import { createRouter, createWebHashHistory } from 'vue-router'
import routes from "./routes.js";
import store from "../store/index.js";

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    if (store.state.isAuthenticated == true) {
      next("/orders");
    }
    else {
      next();
    }
  }
  else {
    if (store.state.isAuthenticated == false) {
      if (to.path == "/feedback") {
        next();
      }
      else {
        next("/")
      }
    }
    else {
      next();
    }
  }
});



export default router
