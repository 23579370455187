<template>
  <div class="goback-btn">
    <router-link to="/dashboard">
      <span class="fas fa-circle">
        <span class="fa-solid fa-times"></span>
      </span>
    </router-link>
  </div>
</template>

<style scoped>
.fa-times {
  position: absolute;
  color: white;
  font-size: 20px;
  left: 8px;
  top: 4px;
}

.fa-circle {
  position: relative;
  top: -2px;
  font-size: 28px;
  font-weight: bolder;
  color: #f53939;
  border-radius: 50%;
  float: right;
}
.fa-circle:hover {
  box-shadow: 0px 1px 2px 0px red;
  color: crimson;
}
.goback-btn {
  position: relative;
  justify-content: right;
  right: 5px;
  float: right;
  cursor: pointer;
  top: 2px;
  z-index: 99;
}

@media only screen and (max-width: 600px) {
  .fa-circle {
    position: relative;
    top: -4px;
    right: -4px;
    font-size: 23px;
    font-weight: bolder;
    color: #f53939;
    border-radius: 50%;
    float: right;
  }
  .fa-times {
    position: absolute;
    color: white;
    font-size: 14px;
    left: 7px;
    top: 5px;
  }
}
</style>
