<!-- @format -->
<template>
  <teleport to="#app">
    <confirm-box ref="msgbox"></confirm-box>
  </teleport>
  <div
    :class="showFull == true ? 'notify-popup openmore' : 'notify-popup'"
    v-if="ShowBox">
    <div class="tophead">Notifications</div>
    <div class="markread" @click="markAllRead()">Mask all as read</div>
    <div class="notlist">
      <div
        v-for="row in notifylist"
        :key="row.trackno"
        class="notrow"
        @click="markRead(row.trackno)">
        <div class="icondiv">
          <div :class="row.seenflg != 'Y' ? 'blueicon' : 'greyicon'">
            <i
              :class="
                row.seenflg != 'Y'
                  ? 'fa fa-bell bellicon notify-icon-animate'
                  : 'fa fa-bell bellicon'
              "></i>
          </div>
        </div>
        <div class="msg">
          <span style="position: relative; font-size: 15px; font-weight: 600"
            >{{ row.notifytitle }}({{ row.trackno }})</span
          >
          <br />
          {{ row.msg }}
        </div>
        <br />
        <div class="readlink">
          <div class="left">Date: {{ row.notifydt }}</div>
          <span class="seentm">Seen : {{ row.seendatetime }}</span>

          <div
            class="right"
            v-if="row.seenflg != 'Y'"
            @click="markRead(row.trackno)">
            Mask as Read
          </div>
        </div>
      </div>
    </div>
    <div class="btn-div tocenter">
      <button
        type="button"
        ref="showmore"
        class="btn btn-default"
        @click="showMore()">
        Show More
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["ShowBox"],
  emits: ["mark-read"],
  data() {
    return {
      notifylist: [],
      showFull: false,
      reclimit: 5,
    };
  },
  methods: {
    async getList() {},
    async markRead() {},
    async markAllRead() {},
    async showMore() {
      this.showFull = !this.showFull;
      if (this.showFull == true) {
        this.reclimit = 5000;
      } else {
        this.reclimit = 5;
      }
      await this.getList();

      this.$refs.showmore.innerText = this.showFull ? "Show Less" : "Show More";
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style scoped>
.notify-popup {
  position: absolute;
  float: right;
  right: 25px;
  width: 450px;
  min-height: 300px;
  height: fit-content;
  z-index: 1001;
  padding: 15px;
  border-radius: 6px;
  color: black;
  background-color: white;
  box-shadow: 0px 1px 1px 1px #959595;
  margin-top: 5px;
  top: 40px;
}
.markread {
  position: relative;
  text-align: right;
  width: calc(100% - 20px);
  font-size: 12px;
  text-decoration: underline;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 5px;
  cursor: pointer;
}
.tophead {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}
.notlist {
  max-height: 250px;
  min-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.notrow {
  position: relative;
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 1px lightgrey;
  color: black;
  margin: 5px;
  width: calc(100% - 20px);
  padding: 5px;
  cursor: pointer;
}
.notrow .icondiv,
.notrow .msg {
  display: inline-block;
}
.icondiv {
  width: 45px;
  vertical-align: top;
}
/* read notification  */
.greyicon {
  width: 25px;
  height: 25px;
  text-align: center;
  color: black;
  background-color: #cfcfcf;
  border: 1px solid #cfcfcf;
  border-radius: 50%;
  margin: auto;
  margin-top: 5px !important;
}
/* unread notification  */
.blueicon {
  width: 25px;
  height: 25px;
  text-align: center;
  color: white;
  background-color: #116ee9;
  border: 1px solid #116ee9;
  border-radius: 50%;
  margin: auto;
  margin-top: 10px !important;
}
.bellicon {
  margin: auto;
  margin-top: 5px;
  font-size: 13px;
}
.msg {
  padding-right: 0px;
  width: calc(100% - 50px);
  font-size: 12px;
}
.readlink {
  color: black;
  font-size: 12px;
  margin-bottom: 5px;
  width: 100%;
  margin-top: 5px;
}
.readlink .left {
  text-align: left;
  display: inline-block;
  width: 50%;
  padding-left: 45px;
}
.readlink .right {
  text-align: right;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
  width: calc(50% - 60px);
}
.notify-icon-animate {
  animation: ring 1.6s linear infinite;
}
.btn-div {
  margin-top: 10px;
}
.openmore {
  height: 870px !important;
}
.openmore .notlist {
  min-height: 760px !important;
  max-height: 760px !important;
}

.seentm {
  font-size: 9px;
  color: darkgrey;
  font-weight: bolder;
  display: inline-block;
  text-align: right;
  width: 100%;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: lightgray white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  cursor: pointer;
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid gray;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid gray;
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  35% {
    transform: rotate(-15deg);
  }
  45% {
    transform: rotate(10deg);
  }
  55% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@media only screen and (max-width: 600px) {
  .notify-box {
    width: 150px;
  }
  .notify-popup {
    width: 92%;
    min-height: 300px;
    padding: 8px;
    top: 35px;
    left: 7px;
  }
}
</style>
