<template>
  <top-bar v-if="isAuthenticated"></top-bar>
  <div class="main-div">
    <teleport to="#app">
      <message-box ref="msgbox"></message-box>
      <confirm-box ref="confirmbox"></confirm-box>
    </teleport>
    <audio id="myAudio" src="orderalert.mp3"></audio>

    <div class="notifyclass" v-if="shwalertBox">
      <notify-alert
        v-for="row in notifyList"
        :key="row.trackno"
        :NotifyText="row.msg"
        :NotifyTrackno="row.trackno"
        :NotifyTitle="row.notifytitle"
      ></notify-alert>
    </div>
    <router-view />
  </div>
  <foot-bar v-if="isAuthenticated"></foot-bar>
  <!-- <div class="notifyclass"> -->

  <!-- </div> -->
</template>

<script>
// import axios from "axios";
import TopBar from "@/components/TopBar.vue";
import FootBar from "@/components/FootBar.vue";
import NotifyAlert from "@/components/NotifyAlert.vue";

export default {
  components: {
    TopBar,
    FootBar,
    NotifyAlert,
  },
  data() {
    return {
      download1: true,
      download2: true,
      downloadKot: true,
      upload1: true,
      smsresp: true,
      notifyList: [],
      shwalertBox: false,
      audio: null,
    };
  },
  methods: {
    checkAuth() {
      if (localStorage.getItem("Auth") == "Y") {
        this.$store.state.isAuthenticated = true;
        this.$store.state.userId = localStorage.getItem("Uid");
        this.$store.state.userName = localStorage.getItem("Uname");
      } else {
        this.$store.state.isAuthenticated = false;
        this.shwalertBox = false;
      }
    },

    // Play Sound on Order Arrival
    playSound() {
      this.audio.play();
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
  },
  async mounted() {
    this.audio = document.getElementById("myAudio");
    this.checkAuth();
  },
};
</script>

<style>
html {
  margin: 0px;
  padding: 0px;
  clear: both;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
body {
  margin: 0px;
  padding: 0px;
  clear: both;
  background-color: rgb(240, 238, 238);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

:root {
  --el-color-primary: rgb(17, 75, 235) !important;
  --el-color-success: rgb(23, 201, 100) !important;
  --el-color-danger: rgb(242, 19, 93) !important;
  --el-color-warn: rgb(254, 130, 0) !important;
  --el-color-dark: rgb(36, 33, 69) !important;
}

.el-button {
  border-radius: 20px !important;
  font-size: 15px !important;
  font-weight: bold !important;
}
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
}
.main-div {
  position: relative;
  display: block;
  top: 0px;
  height: calc(100vh - 60px);
  background-size: cover;
}
.notifyclass {
  z-index: 99;
  position: absolute;
  float: right;
  width: 300px;
  top: 60px;
  height: fit-content;
  right: 0px;
  background: none;
  right: 35px;
}
.msgtable {
  position: relative;
  margin-top: 15px;
  width: 100%;
  border-collapse: collapse;
}
.msgtable tr:first-child td {
  font-weight: bold;
  background-color: lightgray;
}
.msgtable tr td {
  border: 1px solid lightgray;
}
</style>
<style>
@import "./css/formcontrol.css";
</style>
